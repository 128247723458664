let realEstate = {
    tools: [
        {text: "BoldLeads", value: "BoldLeads", src: "boldleads.png"},
        {text: "BoomTown", value: "BoomTown", src: "boomtown.png"},
        {text: "Brivity", value: "Brivity", src: "brivity.png"},
        {text: "ColeRealty", value: "ColeRealty", src: "colerealty.png"},
        {text: "Command", value: "Command", src: "command.png"},
        {text: "Commissions Inc.", value: "CommissionsInc", src: "cinc.png"},
        {text: "ConstantContact", value: "ConstantContact", src: "constantcontract.png"},
        {text: "Contactually", value: "Contactually", src: "Contactually.png"},
        {text: "DocuSign", value: "DocuSign", src: "docusign.png"},
        {text: "Dotloop", value: "Dotloop", src: "dotloop.png"},
        {text: "Follow-up Boss", value: "FollowUpBoss", src: "followupboss.png"},
        {text: "InfusionSoft", value: "InfusionSoft", src: "infusionsoft.png"},
        {text: "Kunversion", value: "Kunversion", src: "Kunversion.png"},
        {text: "Landvoice", value: "Landvoice", src: "Landvoice.png"},
        {text: "Liondesk", value: "Liondesk", src: "liondesk.png"},
        {text: "MailChimp", value: "MailChimp", src: "mailchimp.png"},
        {text: "MarketLeader", value: "MarketLeader", src: "MarketLeader.png"},
        {text: "Mojo Dialer (or other auto dialers)", value: "MojoDialer", src: "mojo.png"},
        {text: "Prime Seller Leads", value: "PrimeSellerLeads", src: "primesellerleads.png"},
        {text: "Real Geeks", value: "RealGeeks", src: "realgeeks.png"},
        {text: "Realty.com", value: "Realty", src: "Realty.png"},
        {text: "RedX", value: "RedX", src: "redx.png"},
        {text: "Skyslope", value: "Skyslope", src: "skyslope.png"},
        {text: "SmartZip", value: "SmartZip", src: "smartzip.png"},
        {text: "Top Producer", value: "TopProducer", src: "topproducer.png"},
        {text: "Trulia", value: "Trulia", src: "trulia.png"},
        {text: "Vulcan7", value: "Vulcan7", src: "vulcan7.png"},
        {text: "WordPress", value: "WordPress", src: "wordpress.png"},
        {text: "Zillow", value: "Zillow", src: "zillow.png"},
    ],
    tasks: {
        admin: [
            "CMA",
            "CRM Tool Management",
            "Calendar Coordination",
            "Coordinates with Escrow and Inspection Companies",
            "Database Management",
            "E-mail Management",
            "Enter/Update Listing in MLS",
            "Follow-up with Buyer / Sellers for Contract Completion",
            "Listings Management",
            "Offer worksheets",
            "Phone Receptionist",
            "Phone call coordination/scheduling with Vendors and Clients (Non-sales calls)",
            "Process documents",
            "Reports",
            "Research/Data Gathering",
            "Upload Listings on Social Media & MLS Syndication sites",
        ],
        marketing: [
            "Basic SEO",
            "Blogging",
            "Creates Virtual Tours",
            "Designs, Creates and Manages Media Content (i.e. Flyers, Postcards, Newsletters)",
            "E-mail marketing/e-campaigns",
            "Enhances & Manages Social Media Sites (Facebook, Twitter, LinkedIn, Instagram and YouTube)",
            "Photo & Video Editing",
            "Website Maintenance (Content Management)",
            "Wordpress Administration",
        ],
        prospecting: [
            "Buyers",
            "Buying Needs or Home Preferences",
            "CRM Tool Management",
            "Checks for pre-approval status",
            "Circle Prospecting",
            "Coordinates with Agents for SetAppointments",
            "Craig Proctor/Universal Call Back Script",
            "Expired",
            "FSBO",
            "Face-to-face Appointment",
            "Geo Farming",
            "Handling Objections",
            "Home Seller- Expired/FSBO",
            "Identifies Time Frame",
            "Identifies any existing contracts with other Agents",
            "Identifies factors for not selling",
            "Just Listed/Just Solds",
            "LPMAMA",
            "Phone Appointment",
            "Renters",
            "Sellers",
            "Selling Price",
            "Tom Ferry",
            "Updates and Manage All Your Lead Profiles",
            "Value Propositions",
        ],
        support: [
            "Answer Product Questions",
            "Answer Sales Questions",
            "Answer Technical Questions",
            "Handle client escalations",
            "Provide feedback to drive process improvements",
            "Solve client issues through calls, email, text, and online chat",
            "Submit requests, tickets, cancellations, scheduling, campaigns, and other functions within the customer CRM",
        ],
    },
};

let realEstateCardJobs = {
    admin: [
        "VIRTUAL RECEPTIONIST",
        "ADMINISTRATIVE ASSISTANT",
        "TRANSACTION COORDINATOR",
        "LOAN PROCESSOR ASSISTANT",
    ],
    prospecting: [
        "PROSPECTING ISA",
        "RECRUITING ASSISTANT",
        "TELEMARKETING & INSIDE SALES",
    ],
    support: [
        "PERSONAL & EXECUTIVE ASSISTANT",
        "HELP DESK & SUPPORT",
        "CUSTOMER SERVICE",
        "CHAT SUPPORT"
    ],
    marketing: [
        "Assistant Marketing Coordinator",
        "Graphics & Video Editor",
        "Social Media Management",
        "Email & Content Marketing Assistant",
    ]
}

let genericCardJobs = {
    admin: [
        "VIRTUAL RECEPTIONIST",
        "PROCESS ASSISTANT",
        "PROJECT COORDINATOR",
    ],
    prospecting: [
        "RECRUITING ASSISTANT",
        "TELEMARKETING & INSIDE SALES",
        "SALES DEVELOPMENT REPRESENTATIVE",
        "SALES ASSISTANT"
    ],
    support: [
        "PERSONAL & EXECUTIVE ASSISTANT",
        "HELP DESK & SUPPORT",
        "CUSTOMER SERVICE",
        "CHAT SUPPORT"
    ],
    marketing: [
        "MARKETING ASSISTANT",
        "GRAPHICS & VIDEO EDITOR",
        "SOCIAL MEDIA MANAGER",
        "EMAIL & CONTENT MARKETING ASSISTANT",
    ]
}


module.exports = {
    realEstate,
    realEstateCardJobs,
    genericCardJobs
};
