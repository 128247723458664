<template>
    <transition
            mode="out-in"
            enter-active-class="transition duration-150 ease-out transform"
            enter-class="opacity-0 translate-x-full"
            enter-to-class="opacity-100 translate-x-0"
            leave-active-class="transition duration-150 ease-in transform"
            leave-class="opacity-100 translate-x-0"
            leave-to-class="opacity-0 -translate-x-full"
    >
        <div v-if="spin" class="flex absolute top-0 right-0 flex-col w-full h-full font-semibold text-center bg-white bg-opacity-95 lg:justify-center" style="z-index: 999999">
            <div class="flex flex-wrap justify-center p-4 mt-32 lg:mt-0">
                <h3 class="w-full text-xl leading-relaxed sm:text-3xl">
                    <slot/>
                </h3>
                <svg class="mt-8 w-24 h-24 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle
                            class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            stroke-width="4"
                    />
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Spinner",
        props: {
            spin: {type: Boolean, default: false}
        }
    };
</script>

<style scoped>

</style>
