<template>
    <div class="flex relative flex-col">
        <div class="flex flex-col flex-grow flex-shrink-0">
            <div class="flex absolute top-0 left-0 justify-center items-center -m-4 w-16 h-16 font-bold text-white bg-purple-700 rounded-md shadow-md button">
                <div class="flex justify-center items-center">
                    <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                    </svg>
                    <span class="pr-1 text-2xl">{{ order.quantity }}</span>
                </div>
            </div>
            <slot name="img"/>
            <div class="p-4" :class="{'flex-grow flex-shrink-0': order.quantity === 0 || selecting}">
                <h3 v-if="!selecting" class="mt-6 text-xl tracking-widest leading-6 text-center text-gray-900 capitalize">
                    <slot name="title"/>
                </h3>
                <h4 v-if="!selecting" class="text-xl tracking-widest leading-6 text-center capitalize text-brand-500 text-mod-500">
                    <slot name="sub-title"/>
                </h4>
                <div v-if="!selecting && (order.quantity < 1 || isRealEstate)" class="hidden overflow-hidden md:block">
                    <p class="overflow-hidden px-2 mt-6 leading-loose text-gray-600">
                        <slot name="supporting-text"/>
                    </p>
                </div>
                <div v-if="selecting" class="flex flex-col w-full min-h-full bg-white">
                    <h3 class="mt-4 mb-4 tracking-widest leading-6 text-center text-gray-800 capitalize">
                        QUANTITY
                    </h3>
                    <div class="flex justify-center items-center">
                        <button :id="createId('decrement')" class="inline-flex items-center transition duration-150 text-mod-500 focus:outline-none hover:text-mod-600" @click="$emit('decrement')">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-6 h-6 fill-current">
                                <path d="M10 20a10 10 0 110-20 10 10 0 010 20zm5-11H5v2h10V9z"/>
                            </svg>
                        </button>
                        <span class="px-8 mb-1 text-3xl tracking-widest leading-6 text-center text-gray-700 capitalize">{{ order.quantity }}</span>
                        <button :id="createId('increment')" class="inline-flex items-center transition duration-150 text-mod-500 focus:outline-none hover:text-mod-600" @click="$emit('increment')">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-6 h-6 fill-current">
                                <path d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 110-20 10 10 0 010 20z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="text-center">
                        <button class="mt-4 text-sm font-semibold text-center text-purple-500 uppercase tracking-weider hover:text-purple-600 hover:underline" @click="removeAll">
                            Remove All
                        </button>
                    </div>
                    <ValidationObserver v-if="!isRealEstate" ref="toolsets" class="w-full">
                        <div class="grid grid-cols-1 gap-4 p-4">
                            <div class="col-span-1">
                                <ValidationProvider v-slot="{errors}" rules="required|max:32768">
                                    <label :for="createId('outcome')" class="block text-sm font-medium leading-5 text-gray-700" :class="{'text-red-600 font-semibold': errors[0]}">Specific Outcome Of Role</label>
                                    <div class="relative">
                                        <textarea :id="createId('outcome')" v-model="description.outcome" rows="3" :class="{'border-red-500': errors[0]}" class="block w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out resize-none focus:border-purple-500 sm:text-sm sm:leading-5"/>
                                        <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-1">
                                <ValidationProvider v-slot="{errors}" rules="required|max:32768">
                                    <label :for="createId('tools')" class="block text-sm font-medium leading-5 text-gray-700" :class="{'text-red-600 font-semibold': errors[0]}">Technology, Systems, Tools</label>
                                    <div class="relative">
                                        <textarea :id="createId('tools')" v-model="description.tools" rows="3" :class="{'border-red-600': errors[0]}" class="block w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out resize-none focus:border-purple-500 sm:text-sm sm:leading-5"/>
                                        <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-1">
                                <ValidationProvider v-slot="{errors}" rules="required|max:50000">
                                    <label :for="createId('other')" class="block text-sm font-medium leading-5 text-gray-700" :class="{'text-red-600 font-semibold': errors[0]}">Skills + Additional Preferences</label>
                                    <div class="relative">
                                        <textarea :id="createId('other')" v-model="description.other" rows="3" :class="{'border-red-500': errors[0]}" class="block w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out resize-none focus:border-purple-500 sm:text-sm sm:leading-5"/>
                                        <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </ValidationObserver>
                    <div v-if="order.quantity >= 0 && selecting" class="flex flex-col flex-grow justify-end px-4">
                        <button :id="createId('update')" class="px-4 py-2 w-full font-medium tracking-wide text-white rounded bg-mod-500" @click.stop.prevent="validateFields">
                            UPDATE
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="!selecting && (order.quantity < 1 || isRealEstate)" class="h-56">
                <ul class="justify-start px-4 text-sm text-gray-600">
                    <li v-for="(item, index) in listItems" :key="index" class="flex items-center mt-4">
                        <CheckSvg class="flex-shrink-0 text-mod-400"/>
                        <span class="ml-4 uppercase">{{ item }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="!selecting && order.quantity > 0 && !isRealEstate" class="p-4 space-y-4 text-center">
                <div>
                    <h5 class="font-semibold">
                        Outcome Of Role
                    </h5>
                    {{ order.outcome }}
                </div>
                <div>
                    <h5 class="font-semibold">
                        Technology, Systems, Tools
                    </h5>
                    {{ order.tools }}
                </div>
                <div>
                    <h5 class="font-semibold">
                        Skills + Additional Preferences
                    </h5>
                    {{ order.other }}
                </div>
            </div>
        </div>
        <div v-if="!selecting" class="h-16">
            <div v-if="order.quantity > 0 && !selecting" class="flex items-center p-4 h-full bg-gray-50">
                <div class="w-1/2">
                    <button :id="createId('remove_all')" class="text-sm font-semibold text-center text-red-500 uppercase tracking-weider hover:text-red-600 hover:underline" @click.stop.prevent="removeAll">
                        Remove
                    </button>
                </div>
                <div class="flex justify-end items-center w-1/2">
                    <div class="flex items-center text-center">
                        <span class="mr-2 text-sm text-gray-600 uppercase">Quantity</span> <span class="mx-1">x</span> <span class="font-extrabold">{{ order.quantity }}</span>
                    </div>
                    <button :id="createId('modify')" class="ml-4 text-sm font-semibold text-center text-purple-500 uppercase tracking-weider hover:text-purple-600 hover:underline" @click.stop.prevent="selecting = !selecting">
                        Modify
                    </button>
                </div>
            </div>
            <div v-if="order.quantity < 1 && !selecting" class="p-4">
                <button :id="createId('select')" class="px-4 py-2 w-full font-medium text-white rounded bg-mod-500" @click.stop.prevent="handleSelect">
                    SELECT
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import CheckSvg from "@/components/Svg/CheckSvg";
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import {mapGetters} from "vuex";

    export default {
        name: "VaCard",
        components: {CheckSvg, ValidationProvider, ValidationObserver},
        props: {
            order: {
                type: Object,
                default: () => {
                    return {
                        outcome: "",
                        tools: "",
                        other: "",
                        quantity: 0,
                        predefined: {
                            tools: [],
                            tasks: [],
                        }
                    };
                },
            },
            type: {type: String, default: null},
            listItems: {type: Array, default: () => []},
        },
        data() {
            return {
                description: {
                    outcome: this.order.outcome,
                    tools: this.order.tools,
                    other: this.order.other,
                    predefined: {
                        tools: this.order.predefined.tools,
                        tasks: this.order.predefined.tasks,
                    }
                },
                selecting: false,
            };
        },
        computed: {
            ...mapGetters([
                "industry",
            ]),
            isRealEstate() {
                return this.industry === "Real Estate";
            },
        },
        methods: {
            handleSelect() {
                this.$emit("increment");
                this.$emit("editing");
                this.selecting = true;
            },
            removeAll() {
                this.$emit("update", {type: this.type, value: 0});
                this.selecting = false;
            },
            createId(prefix) {
                return prefix + "_" + this.type;
            },
            async validateFields(){
                if(!this.isRealEstate && await this.$refs.toolsets.validate()){
                    this.$emit("update-tasks", {
                        type: this.type,
                        ...this.description
                    });
                    this.selecting=false
                }
                if(this.isRealEstate) {
                    this.selecting = false;
                }

            }
        },
    };
</script>

<style scoped>

</style>
