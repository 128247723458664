<template>
    <div>
        <h3 class="text-lg font-medium leading-6 text-gray-900">
            Virtual Assistant Schedule
        </h3>
        <p class="hidden mt-1 max-w-2xl text-sm leading-5 text-gray-600 md:block">
            Tell us when you would like your Virtual Professional to work.
        </p>
        <ValidationObserver ref="schedule">
            <div class="mt-6 min-h-3/4">
                <div class="grid grid-cols-1 gap-6 max-w-2xl">
                    <div class="flex grid grid-cols-9 items-center">
                        <label class="col-span-4">
                            <ValidationProvider v-slot="{errors}" rules="required">
                                <select id="schedule_time_from" v-model="information.start" class="block px-3 py-2 mt-1 w-full bg-white rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out form-select focus:outline-none focus:shadow-outline-purple focus:border-purple-300 sm:text-sm sm:leading-5">
                                    <option v-for="(option, index) in timeIntervals" :key="index" :value="option.value">{{ option.text }}</option>
                                </select>
                                <span class="block mt-2 text-xs text-red-500">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </label>
                        <span class="col-span-1 text-xs text-center uppercase">To</span>
                        <label class="col-span-4">
                            <ValidationProvider v-slot="{errors}" rules="required">
                                <select id="schedule_time_to" v-model="information.end" class="block px-3 py-2 mt-1 w-full bg-white rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out form-select focus:outline-none focus:shadow-outline-purple focus:border-purple-300 sm:text-sm sm:leading-5">
                                    <option v-for="(option, index) in timeIntervals" :key="index" :value="option.value">{{ option.text }}</option>
                                </select>
                                <span class="block mt-2 text-xs text-red-500">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </label>
                    </div>

                    <label>
                        <span class="block text-sm font-medium leading-5 text-gray-700">Timezone</span>
                        <ValidationProvider v-slot="{errors}" rules="required">
                            <select id="schedule_timezone" v-model="information.timezone" class="block px-3 py-2 mt-1 w-full bg-white rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out form-select focus:outline-none focus:shadow-outline-purple focus:border-purple-300 sm:text-sm sm:leading-5">
                                <option v-for="(timezone, index) in timezones" :key="index" :value="timezone.name">{{ timezone.name }} - {{ timezone.alternativeName }}</option>
                            </select>
                            <span class="block mt-2 text-xs text-red-500">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </label>

                    <h4 class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Days Off
                    </h4>

                    <div class="flex grid grid-cols-1 gap-4 px-4 md:grid-cols-7 md:px-0">
                        <label v-for="(day, index) in days" :key="index" class="flex grid grid-cols-12 items-center md:grid-cols-1 md:text-center md:block md:items-start">
                            <span class="flex grid-cols-11 md:justify-center md:grid-cols-1">
                                <input :id="'day-' + day.name" v-model="information.restDays" type="checkbox" :value="day.value" class="w-5 h-5 rounded border-gray-300 focus:ring focus:ring-mod-500 text-mod-500">
                            </span>
                            <span class="grid-cols-1 text-sm leading-5">{{ day.name }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <div class="grid grid-cols-1 gap-4 mt-6 md:hidden">
            <BackButton class="flex justify-center text-center" @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton class="flex justify-center text-center" @click.stop.prevent="validate">
                Continue
            </ContinueButton>
        </div>
        <div class="hidden justify-center items-center space-x-4 md:flex">
            <BackButton @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton @click.stop.prevent="validate">
                Continue
            </ContinueButton>
        </div>
    </div>
</template>

<script>
    import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
    import {getTimeZones} from "@vvo/tzdb";

    const dayjs = require("dayjs");
    const timezone = require("dayjs/plugin/timezone");
    dayjs.extend(timezone);
    import {timeIntervals} from "@/assets/locales";
    import {mapActions, mapGetters} from "vuex";
    import ContinueButton from "@/components/ContinueButton";
    import BackButton from "@/components/BackButton";
    import {required} from "vee-validate/dist/rules";

    extend("required", {
        ...required,
        message: "This field is required",
    });

    export default {
        name: "Schedule",
        components: {BackButton, ContinueButton, ValidationObserver, ValidationProvider},
        data() {
            return {
                timeIntervals: timeIntervals,
                information: {},
                startTime: null,
                timezones: [],
                days: [
                    {name: "Monday", value: "Mon"},
                    {name: "Tuesday", value: "Tue"},
                    {name: "Wednesday", value: "Wed"},
                    {name: "Thursday", value: "Thu"},
                    {name: "Friday", value: "Fri"},
                    {name: "Saturday", value: "Sat"},
                    {name: "Sunday", value: "Sun"},
                ],
            };
        },
        computed: {
            ...mapGetters([
                "getInformation",
                "adminVas",
                "marketingVas",
                "prospectingVas",
                "supportVas",
                "industry"
            ]),
            isRealEstate(){
                return this.industry === "Real Estate";
            }
        },
        created() {
            this.information = this.getInformation("schedule");
            this.information.timezone = dayjs.tz.guess();
            this.timezones = getTimeZones().filter(timezone => {
                if (timezone.countryCode === "US" || timezone.countryCode === "CA") {
                    return timezone;
                }
            });
            if(this.isRealEstate){
                document.title = "Step 4: Schedule";
            }
            else{
                document.title = "Step 3: Schedule";
            }
        },
        methods: {
            ...mapActions([
                "updateRegistration",
                "decrementStep",
            ]),
            async validate() {
                let valid = await this.$refs.schedule.validate()
                if(!valid){
                    return
                }
                this.updateRegistration({data: this.information, key: "schedule"});

                this.$emit("continue");
            },
        },
    };

</script>
