<template>
    <span class="block mt-2 text-sm font-semibold text-red-600">
        <slot/>
    </span>
</template>

<script>
    export default {
        name: "ErrorText",
    };
</script>

<style scoped>

</style>
