<template>
    <div class="flex flex-col justify-center items-center w-full h-screen">
        <img class="mb-24 rounded-t-lg" src="./../assets/modlogo.png" alt="MyOutDesk">
        <div>
            <h3 class="mb-12 text-3xl tracking-widest leading-6 text-center capitalize text-brand-500 text-mod-500">
                All done!
            </h3>
            <p class="max-w-lg tracking-widest leading-6 text-center text-gray-900">
                One of our placements specialists will be in contact with you shortly.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Completed',
    }
</script>
