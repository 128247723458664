<template>
    <button type="button" class="inline-flex items-center px-4 py-2 text-lg font-medium leading-6 bg-opacity-75 rounded-md border transition duration-150 ease-in-out disabled:bg-mod-300 text-mod-500 border-mod-500 hover:bg-mod-50 focus:outline-none focus:border-mod-700 focus:shadow-outline-blue active:bg-mod-700 back-button" @click="$emit('click', $event)">
        <span class="mr-1">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/></svg>
        </span>
        <slot/>
    </button>
</template>

<script>
    export default {
        name: "BackButton",
    };
</script>

<style scoped>

</style>
