<template>
    <div>
        <div class="min-h-3/4">
            <div class="hidden md:block">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    You're almost there!
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
                    Please review the information one last time
                </p>
            </div>
            <div class="gap-4 mt-4 lg:grid lg:grid-cols-3">
                <div class="col-span-2 rounded-t shadow">
                    <div class="p-6 bg-gradient-to-br rounded-t from-mod-500 to-mod-400">
                        <h3 class="text-xl font-semibold text-white">
                            Order Review
                        </h3>
                    </div>
                    <div class="grid grid-cols-1 gap-2 p-6 md:grid-cols-2">
                        <div>
                            <h3 class="mt-4 mb-4 font-semibold lg:mb-1 lg:mt-0">
                                Virtual Assistant Details
                            </h3>
                            <div class="space-y-2 text-sm text-gray-700">
                                <div v-for="(vaType, index) in order" v-if="vaType.quantity !== 0" :key="index">
                                    <div class="flex items-center">
                                        <span class="inline-flex items-center px-3 py-1 mr-2 font-bold rounded bg-mod-200 text-mod-800">
                                            {{ vaType.quantity }}
                                        </span>
                                        <span class="mr-1 font-bold uppercase">{{ index }}</span> <span class="text-xs font-semibold font-gray-700">Virtual Assistant</span>
                                    </div>
                                    <div class="pl-10 text-sm">
                                        <p>
                                            <span class="mr-1 font-bold text-mod-800">Outcome:</span> {{ vaType.outcome }}
                                        </p>
                                        <p>
                                            <span class="mr-1 font-bold text-mod-800">Tools:</span> {{ vaType.tools }}
                                        </p>
                                        <p>
                                            <span class="mr-1 font-bold text-mod-800">Additional:</span> {{ vaType.other }}
                                        </p>
                                        <div v-if="vaType.predefined.tasks.length > 0">
                                            <p class="mt-1 font-bold text-mod-800">
                                                Predefined Tasks
                                            </p>
                                            <span v-for="(tool, innerIndex) in vaType.predefined.tasks" :key="innerIndex">
                                                {{ tool }}<span v-if="innerIndex < vaType.predefined.tasks.length -1">,</span>
                                            </span>
                                        </div>
                                        <div v-if="vaType.predefined.tools.length > 0">
                                            <p class="mt-1 font-bold text-mod-800">
                                                Predefined Tools
                                            </p>
                                            <span v-for="(tool, innerIndex) in vaType.predefined.tools" :key="innerIndex">
                                                {{ tool }}<span v-if="innerIndex < vaType.predefined.tools.length -1">,</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4 border-t border-gray-400"/>
                            <div>
                                <h3 class="mb-1 font-semibold">
                                    Schedule
                                </h3>
                                <div class="pl-8">
                                    <p>{{ schedule.start }} - {{ schedule.end }} <span class="text-sm text-gray-700">({{ schedule.timezone }})</span></p>
                                    <div class="text-sm text-gray-700">
                                        <span>
                                            Days Off:
                                        </span>
                                        <span v-for="(dayOff, index) in schedule.restDays" :key="index">
                                            {{ getDayOfWeek(dayOff, index) }}<span v-if="index < schedule.restDays.length -1">,</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-8 rounded-t shadow lg:mt-0">
                    <div class="p-6 bg-gradient-to-br rounded-t from-mod-500 to-mod-400">
                        <h3 class="text-xl font-semibold text-white">
                            Order Summary
                        </h3>
                    </div>
                    <div class="p-6">
                        <div class="text-sm text-gray-700">
                            <div class="flex relative p-4 mb-4 text-black bg-opacity-10 rounded border bg-mod-200" :class="{'border-transparent': showPlanChanger}">
                                <div>
                                    <h1 id="checkout-plan-title" class="flex items-center text-xl font-semibold uppercase">
                                        {{ getPlanMonths() }} Month Plan
                                    </h1>
                                    <h3 class="text-lg text-gray-900">
                                        <span class="mr-1">$</span><span class="font-medium">{{ getPlanPrice(meta.plan) }}</span> <span class="text-xs">/ month</span>
                                        <span class="mx-2">&middot;</span>
                                        <button id="checkout-change-plan" class="text-xs font-medium text-blue-600" @click="showPlanChanger = !showPlanChanger">
                                            change plan
                                        </button>
                                    </h3>
                                </div>
                                <transition
                                        enter-class="opacity-0 transform scale-95"
                                        leave-class="opacity-100 transform scale-100"
                                        enter-active-class="transition duration-100 ease-out"
                                        enter-to-class="opacity-100 transform scale-100"
                                        leave-active-class="transition duration-75 ease-in"
                                        leave-to-class="opacity-0 transform scale-95"
                                >
                                    <div v-if="showPlanChanger" id="checkout-plan-selector" class="absolute top-0 left-0 z-10 space-y-2 w-full bg-gray-50 rounded border">
                                        <div class="p-4">
                                            <h1 class="flex items-center text-xl font-semibold uppercase">
                                                12 Month Plan <span class="ml-2 text-sm font-medium capitalize text-mod-600">🚀 Most Popular!</span>
                                            </h1>
                                            <h3 class="text-lg text-gray-900">
                                                <span class="mr-1">$</span><span class="font-medium">{{ getPlanPrice("12m") }}</span> <span class="text-xs">/ month</span>
                                                <span class="mx-2">&middot;</span>
                                                <button id="checkout-select-12m-plan" class="text-xs font-medium text-blue-600" @click="handlePlanChange('12m')">
                                                    <span>select plan</span>
                                                </button>
                                            </h3>
                                        </div>
                                        <div class="p-4">
                                            <h1 class="flex items-center text-xl font-semibold uppercase">
                                                6 Month Plan
                                            </h1>
                                            <h3 class="text-lg text-gray-900">
                                                <span class="mr-1">$</span><span class="font-medium">{{ getPlanPrice("6m") }}</span> <span class="text-xs">/ month</span>
                                                <span class="mx-2">&middot;</span>
                                                <button id="checkout-select-6m-plan" class="text-xs font-medium text-blue-600" @click="handlePlanChange('6m')">
                                                    <span>select plan</span>
                                                </button>
                                            </h3>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                            <p class="font-bold">
                                Pricing
                            </p>
                            <p class="mb-2">
                                ${{ getPlanPrice(meta.plan) }} / month
                            </p>
                            <p class="font-bold">
                                Mod Movement
                            </p>
                            <div v-if="meta.modMovementContributor">
                                <p>
                                    ${{ meta.modMovementContribution }}.00 / month, matched by MyOutDesk
                                </p>
                                <p class="mt-1 text-xs italic text-gray-700">
                                    Contributions can be deducted under IRC Section 170
                                </p>
                            </div>
                            <div v-if="!meta.modMovementContributor" class="space-y-2">
                                <p>
                                    You have chosen not to donate to the Mod Movement.
                                </p>
                                <p class="text-mod-700">
                                    Are you <span class="font-bold">SURE</span> you don't want to donate to the Mod Movement? Your donation will be <span class="font-bold">MATCHED</span> by MyOutDesk and help us serve underprivileged communities across the world!
                                </p>
                                <label for="checkout-mod-movement-donation">
                                    <span class="sr-only">MOD Movement Donation</span>
                                    <select
                                            id="checkout-mod-movement-donation" v-model="lastMinuteDonation"
                                            class="block px-3 py-2 mt-1 w-full bg-white rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out form-select focus:outline-none focus:shadow-outline-purple focus:border-purple-300 sm:text-sm sm:leading-5"
                                    >
                                        <option value="">
                                            Don't Donate
                                        </option>
                                        <option value="25">
                                            Donate $25.00
                                        </option>
                                        <option value="50">
                                            Donate $50.00
                                        </option>
                                        <option value="75">
                                            Donate $75.00
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-4">
                <div class="max-w-2xl space-y-4">
                    <div class="p-4 border rounded" :class="[serviceAgreementError ? 'bg-red-50 ring ring-red-500' : 'bg-gray-50 border-gray-400']">
                        <label class="flex justify-center items-center space-x-6">
                            <input v-model="confirmServiceAgreement" type="checkbox" class="w-10 h-10 rounded border border-gray-400 transition duration-150 ease-in-out form-checkbox text-mod-600">
                            <span class="text-sm">
                                I understand that by initiating this Add-On request and clicking FINISH, I am confirming and/or updating, as applicable, my account terms to be the latest <span class="font-semibold">Master Services Agreement </span>(MSA) terms between MyOutDesk and its Clients. I will be receiving a
                                copy, if applicable, of the latest MSA from my MyOutDesk Account Manager for review and signature after submission of this form.
                            </span>
                        </label>
                    </div>
                    <div v-show="serviceAgreementError" class="text-red-500 text-center">
                        You must agree to the Master Services Agreement update to continue.
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden justify-center items-center mt-6 space-x-4 md:flex">
            <BackButton @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton @click="validate">
                Finish
            </ContinueButton>
        </div>
        <div class="grid grid-cols-1 gap-4 mt-6 md:hidden">
            <BackButton class="flex justify-center text-center" @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton class="flex justify-center text-center" @click="validate">
                Finish
            </ContinueButton>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import ContinueButton from "@/components/ContinueButton";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "ReviewOrder",
        components: {ContinueButton, BackButton},
        data() {
            return {
                personal: {},
                meta: {},
                order: {},
                schedule: {},
                showPlanChanger: false,
                lastMinuteDonation: "",
                showModal: false,
                confirmServiceAgreement: false,
                serviceAgreementError: false,
            };
        },
        computed: {
            ...mapGetters({
                getInformation: "getInformation",
                industry: "industry",
            }),
            isRealEstate() {
                return this.industry === "Real Estate";
            },
        },
        watch: {
            confirmServiceAgreement(val){
                if(val){
                    this.serviceAgreementError = false;
                }
            },
            lastMinuteDonation(value) {
                if (value !== "") {
                    this.meta.modMovementContributor = true;
                    this.meta.modMovementContribution = parseInt(this.lastMinuteDonation);
                    this.updateRegistration({data: this.meta, key: "meta"});
                }
            },
        },
        mounted() {
            if (this.isRealEstate) {
                document.title = "Step 6: Review Order";
            } else {
                document.title = "Step 5: Review Order";
            }
            this.personal = this.getInformation("personalInformation");
            this.meta = this.getInformation("meta");
            this.order = this.getInformation("order");
            this.schedule = this.getInformation("schedule");
        },
        methods: {
            ...mapActions([
                "decrementStep",
                "setErrors",
                "setDueToday",
                "updateRegistration",
            ]),
            validate() {
                if(!this.confirmServiceAgreement){
                    this.serviceAgreementError = true;
                    return;
                }
                this.$emit("continue");
            },
            getPlanPrice(plan) {
                switch (plan) {
                    case "12m":
                        return "1988.00";
                    case "6m":
                        return "2188.00";
                }
            },
            getDayOfWeek(day) {
                switch (day) {
                    case "Sun":
                        return "Sunday";
                    case "Mon":
                        return "Monday";
                    case "Tus":
                        return "Tuesday";
                    case "Wed":
                        return "Wednesday";
                    case "Thu":
                        return "Thursday";
                    case "Fri":
                        return "Friday";
                    case "Sat":
                        return "Saturday";
                }
            },
            getPlanMonths() {
                if (this.meta.plan) {
                    return this.meta.plan.split("m")[0];
                }
            },
            handlePlanChange(plan) {
                this.meta.plan = plan;
                this.updateRegistration({data: this.meta, key: "meta"});
                this.showPlanChanger = false;
            },
        },
    };

</script>
