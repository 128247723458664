<template>
    <div>
        <h3 class="pb-4 mb-4 text-lg font-medium leading-6 text-gray-900 border-b">
            <slot name="title"/>
        </h3>

        <div class="grid gap-4" :class="{'grid-cols-1 md:grid-cols-2': hasTasks, 'grid-cols-1': !hasTasks}">
            <PredefinedTasks v-if="hasTasks" :defined-tasks="tasks[type]" :tasks="selectedPredefined" :type="type" @value-select="handleValueSelect"/>
            <div class="grid grid-cols-1 gap-4 mb-12 xl:grid-cols-2">
                <div class="col-span-1">
                    <label :for="createId('outcome')" class="block text-sm font-medium leading-5 text-gray-700">Specific Outcome Of Role</label>
                    <ValidationProvider v-slot="{errors}" rules="required|max:32768">
                        <div class="relative">
                            <textarea :id="createId('outcome')" v-model="description.outcome" :class="{'border-red-500': errors[0]}" rows="5" class="block w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out focus:border-purple-500 sm:text-sm sm:leading-5"/>
                            <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                            </div>
                        </div>
                        <ErrorText>{{ errors[0] }}</ErrorText>
                    </ValidationProvider>
                </div>
                <div class="col-span-1">
                    <label :for="createId('tools')" class="block text-sm font-medium leading-5 text-gray-700">Technology, Systems, Tools</label>
                    <ValidationProvider v-slot="{errors}" rules="required|max:32768">
                        <div class="relative">
                            <textarea :id="createId('tools')" v-model="description.tools" :class="{'border-red-500': errors[0]}" rows="5" class="block w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out focus:border-purple-500 sm:text-sm sm:leading-5"/>
                            <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                            </div>
                        </div>
                        <ErrorText>{{ errors[0] }}</ErrorText>
                    </ValidationProvider>
                </div>
                <div class="col-span-1">
                    <label :for="createId('other')" class="block text-sm font-medium leading-5 text-gray-700">Skills + Additional Preferences</label>
                    <ValidationProvider v-slot="{errors}" rules="required|max:50000">
                        <div class="relative">
                            <textarea :id="createId('other')" v-model="description.other" :class="{'border-red-500': errors[0]}" rows="5" class="block w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out focus:border-purple-500 sm:text-sm sm:leading-5"/>
                            <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                            </div>
                        </div>
                        <ErrorText>{{ errors[0] }}</ErrorText>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {realEstate} from "@/assets/predefined";
    import {extend, ValidationProvider} from "vee-validate";
    import {email, max, required} from "vee-validate/dist/rules";
    import PredefinedTasks from "@/components/PredefinedTasks";
    import ErrorText from "@/components/Shared/ErrorText";

    extend("email", email);
    extend("max", {
        ...max,
        message: "Exceeded Max Length",
    });
    extend("required", {
        ...required,
        message: "This field is required",
    });

    export default {
        name: "JobRequirements",
        components: {ErrorText, PredefinedTasks, ValidationProvider},
        props: {
            type: {type: String, default: ""},
            order: {
                type: Object,
                default: () => {
                    return {
                        tools: [],
                        tasks: []
                    }
                },
            },
        },
        data() {
            return {
                tasks: {},
                description: {
                    outcome: this.order.outcome,
                    tools: this.order.tools,
                    other: this.order.other,
                },
                selectedPredefined: this.order.predefined.tasks,

            };
        },
        computed: {
            ...mapGetters([
                "industry",
            ]),
            hasTasks() {
                return Object.keys(this.tasks).length > 0;
            },
        },
        watch: {
            description: {
                deep: true,
                handler(newValue) {
                    this.$emit("order-update", {
                        order: newValue,
                        type: this.type,
                        predefinedTasks: this.selectedPredefined,
                    });
                },
            },
        },
        created() {
            if (this.industry === "Real Estate") {
                this.tasks = realEstate.tasks;
            }
        },
        methods: {
            handleValueSelect(tasks) {
                this.selectedPredefined = tasks;
                this.$emit("order-update", {
                    order: this.description,
                    type: this.type,
                    predefinedTasks: this.selectedPredefined,
                });
            },
            createId(postFix) {
                return this.type + "_" + postFix;
            },
        },

    };
</script>
