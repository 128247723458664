<template>
    <main id="app">
        <div v-if="testing" class="flex items-center space-x-1">
            <button id="update-button" class="block p-2 m-2 font-medium text-white rounded bg-mod-500" @click.stop.prevent="updateFromTest">
                Update
            </button>
            <button id="save-button" class="block p-2 m-2 font-medium text-white rounded bg-mod-500" @click.stop.prevent="saveForTest">
                Save Current State
            </button>
        </div>
        <Introduction v-if="phase === 'introduction'" @next="startRegistration"/>
        <Register v-if="phase === 'register'" @complete="completeAddonRequest"/>
        <Completed v-if="phase === 'completed'"/>
    </main>
</template>

<script>

    import Introduction from "./components/Introduction";
    import Register from "./components/Register";
    import Completed from "@/components/Completed";
    import {mapActions, mapGetters} from "vuex";
    import { Base64 } from 'js-base64';

    export default {
        name: "App",
        components: {
            Introduction, Register, Completed
        },
        data() {
            return {
                phase: "introduction",
                testing: process.env.VUE_APP_ENV.toLowerCase() === 'test',
            };
        },
        computed: {
            ...mapGetters([
                "step",
                "state",
            ]),
        },
        created() {
            document.title = 'MyOutDesk - Addon Request';
            if(!this.canRestore()) {
                this.$cookie.set('mod_form_version', this.getVersion());
                return;
            }
            this.decodeAndRestoreState();
            this.validateSalesRepUrl();
        },
        methods:{
            ...mapActions([
                "restoreState",
                "setStep",
                "setPromoCode",
                "setSalesRepAlias"
            ]),
            decodeAndRestoreState() {
                try {
                    let firstHalf = this.$cookie.get('mod_form_0')
                    let secondHalf =  this.$cookie.get('mod_form_1')
                    let state;
                    if(firstHalf && secondHalf){
                        state = firstHalf + secondHalf;
                    }
                    if(firstHalf){
                        state = firstHalf;
                    }
                    if(state) {
                        state = decodeURIComponent(state);
                        this.restoreState(JSON.parse(Base64.decode(state)))
                    }
                } catch {
                    this.$cookie.delete('mod_form_version')
                    this.$cookie.delete('mod_form')
                }
            },
            startRegistration() {
                this.phase = 'register';
            },
            completeAddonRequest() {
                this.phase = 'completed';
                this.$cookie.delete('mod_form');
                this.$cookie.delete('mod_form_version');
            },
            updateFromTest()
            {
                if(!this.testing) {
                    return;
                }
                this.decodeAndRestoreState();
                this.$forceUpdate();
            },
            saveForTest()
            {
                if(!this.testing) {
                    return;
                }
                let encodedString = btoa(JSON.stringify(this.state))
                let cookieArray = encodedString.match(/.{1,3800}/g) || [];
                this.$cookie.delete('mod_form_0');
                this.$cookie.delete('mod_form_1');
                cookieArray.forEach((cookie, index) => {
                    this.$cookie.set(`mod_form_${index}`, cookie, {expires: 7});
                })
            },
            canRestore() {
                if(this.testing) {
                    return true;
                }
                let savedVersion = this.$cookie.get('mod_form_version')
                if(savedVersion !== this.getVersion()) {
                    this.$cookie.delete('mod_form_version');
                    this.$cookie.delete('mod_form_0');
                    this.$cookie.delete('mod_form_1');
                    this.$cookie.delete('mod_form_2');
                    return false;
                }
                return true;
            },
            validateSalesRepUrl() {
                let salesRep = window.location.pathname.substring(1);
                if(!salesRep) {
                    return;
                }
                this.$http.post('/validate/link', { alias: salesRep}).then((response) => {
                    if(response.data.valid) {
                        this.setPromoCode(response.data.code)
                        this.setSalesRepAlias(salesRep);
                    }
                }).catch(() => {
                    // invalid sales rep, do nothing
                })
            },
            getVersion() {
                return document.querySelector('meta[name="version"]').getAttribute('content');
            }
        },
    };
</script>
