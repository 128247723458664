import Vue from "vue";
import App from "./App.vue";
import VueCookie from "vue-cookie";
import Vuex from "vuex";
import "./assets/app.css";
import axios from "axios";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if(process.env.VUE_APP_ENV === "production") {
    Sentry.init({
        Vue,
        dsn: "https://0f0b7f31338d49e29e85c17c187459bd@o29515.ingest.sentry.io/5810676",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

let instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

Vue.use(VueCookie);
Vue.use(Vuex);

Vue.config.productionTip = false;
Vue.prototype.$http = instance;
Vue.prototype.$Sentry = Sentry


const defaultJobRequirements = () => {
    return {
        quantity: 0,
        outcome: "",
        tools: "",
        other: "",
        predefined: {
            tasks: [],
            tools: [],
        },
    };
};

const defaultOrder = (fake = false) => {
    if (fake) {
        return {
            admin: {
                quantity: 1,
                outcome: "Order peasants around.",
                tools: "Sword",
                other: "Sheath",
                predefined: {
                    tasks: ['Sharpen Swords', 'Send Ravens', 'Defend The King'],
                    tools: ['Sharpening Stones', 'Twine', 'Steel'],
                },
            },
            marketing: {
                quantity: 1,
                outcome: "Advertise for crime lords facing execution to come work at the wall.",
                tools: "Ravens",
                other: "Twine",
                predefined: {
                    tasks: [],
                    tools: [],
                },
            },
            prospecting: {
                quantity: 1,
                outcome: "Able to travel by horse to the kingdoms far away.",
                tools: "Horse",
                other: "No ponies",
                predefined: {
                    tasks: [],
                    tools: [],
                },
            },
            support: {
                quantity: 1,
                outcome: "Administer medical aid to dying brethren.",
                tools: "Knife",
                other: "Bandages",
                predefined: {
                    tasks: [],
                    tools: [],
                },
            },
        };
    }
    else {
        return {
            admin: defaultJobRequirements(),
            marketing: defaultJobRequirements(),
            prospecting: defaultJobRequirements(),
            support: defaultJobRequirements(),
        };
    }

};

const defaultPredefined = () => {
    return {
        tasks: [],
        tools: [],
    };
};

const defaultState = () => {
    return {
        docuSignKey: null,
        errorMessages: [],
        currentStep: 1,
        personalInformation: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            address: "",
            city: "",
            state: "",
            country: "USA",
            zipCode: "",
            company: "",
            title: "",
            industry: "",
            closedDeals: "",
            currentEmployees: "",
            revenueLastYear: "",
            timezone: "America/Los_Angeles",
            canSign: false
        },
        docusign:{
            key: null,
        },
        order: defaultOrder(),
        schedule: {
            timezone: "",
            start: "08:00 AM",
            end: "05:00 PM",
            restDays: ["Sat", "Sun"],
        },
        billingInformation: {
            firstName: "",
            lastName: "",
            email: "",
            sameAsOfficeAddress: false,
            phoneNumber: "",
            address: "",
            country: "USA",
            city: "",
            state: "",
            zipCode: "",
        },
        meta: {
            promoCode: "",
            signupFee: 397,
            plan: "12m",
            signupFeeConfirmation: 397,
            modMovementContributor: true,
            modMovementContribution: 75,
            docusignReady: false,
            alias: ''
        },
    };
};

const fakeState = () => {
    return {
        docuSignKey: null,
        errorMessages: [],
        currentStep: 5,
        plan: {
            type: "6m",
        },
        personalInformation: {
            firstName: "Jon",
            lastName: "Snow",
            phoneNumber: "5555555555",
            email: "lancet@myoutdesk.com",
            address: "1958 The Top of the Wall",
            city: "Weteros",
            state: "CA",
            country: "USA",
            zipCode: "95816",
            company: "The Night's Watch",
            title: "Lord Commander",
            industry: "Insurance",
            closedDeals: "1",
            currentEmployees: "2",
            revenueLastYear: "3",
            canSign: true,
        },
        docusign:{
            key: null,
        },
        order: defaultOrder(true),
        schedule: {
            timezone: "America/Los_Angeles",
            start: "08:00 AM",
            end: "05:00 PM",
            restDays: ["Sat", "Sun"],
        },
        billingInformation: {
            firstName: "Jon",
            lastName: "Snow",
            email: "lancet@myoutdesk.com",
            sameAsOfficeAddress: true,
            phoneNumber: "5555555555",
            address: "1958 The Top of the Wall",
            country: "USA",
            city: "Weteros",
            state: "CA",
            zipCode: "95816",
        },
        meta: {
            promoCode: "MODFreeSUF2021",
            signupFee: 397,
            plan: "6m",
            signupFeeConfirmation: 397,
            modMovementContributor: true,
            modMovementContribution: 75,
            docusignReady: false,
            alias: ''
        },
    };
};

const store = new Vuex.Store({
    state: (process.env.VUE_APP_ENV === "development") ? fakeState() : defaultState(),
    mutations: {
        "UPDATE_REGISTRATION"(state, payload) {
            state[payload.key] = payload.data;
        },
        "INCREMENT_STEP"(state) {
            state.errorMessages = [];
            state.currentStep++;
            Sentry.setContext(`Step${state.currentStep}`, state);
        },
        "DECREMENT_STEP"(state) {
            state.errorMessages = [];
            state.currentStep--;
        },
        "SET_STEP"(state, step) {
            state.currentStep = step;
        },
        "CLEAR_TASKS"(state) {
            state.vaOrder = defaultOrder();
        },
        "CLEAR_PREDEFINED"(state) {
            Object.keys(state.order).forEach(key => {
                state.order[key].predefined = defaultPredefined();
            });
            state.personalInformation.closedDeals = "";
            state.personalInformation.currentEmployees = "";
            state.personalInformation.revenueLastYear = "";
        },
        "RESTORE_STATE"(state, cookieState) {
            Object.keys(cookieState).forEach(key => {
                state[key] = cookieState[key];
            });
        },
        "RESET_STATE"(state) {
            let restoreState = defaultState();
            Object.keys(defaultState()).forEach(key => {
                state[key] = restoreState[key];
            });
        },
        "SET_DOCUSIGN_KEY"(state, key){
          state.docusign.key = key;
        },
        "SET_DUE_TODAY"(state, amount) {
            if (amount < 0) {
                state.meta.signupFeeConfirmation = 0;
            } else {
                state.meta.signupFeeConfirmation = amount;
            }
        },
        "SET_PROMO_CODE"(state, promoCode) {
            state.meta.promoCode = promoCode;
        },
        "SET_SALES_ALIAS"(state, salesRepAlias) {
            state.meta.alias = salesRepAlias;
        },
        "SET_ERRORS"(state, errors) {
            if (Array.isArray(errors)) {
                state.errorMessages = errors;
            } else {
                state.errorMessages.push(errors);
            }
        },
        "SET_DOCUSIGN_READY"(state, flag){
            state.meta.docusignReady = flag
        }
    },
    actions: {
        updateRegistration({commit}, payload) {
            commit("UPDATE_REGISTRATION", payload);
        },
        decrementStep({commit}) {
            commit("DECREMENT_STEP");
        },
        incrementStep({commit}) {
            commit("INCREMENT_STEP");
        },
        setStep({commit}, step) {
            commit("SET_STEP", step);
        },
        clearTasks({commit}) {
            commit("CLEAR_TASKS");
        },
        clearPreDefined({commit}) {
            commit("CLEAR_PREDEFINED");
        },
        restoreState({commit}, state) {
            commit("RESTORE_STATE", state);
        },
        resetState({commit}) {
            commit("RESET_STATE");
        },
        setDueToday({commit}, amount) {
            commit("SET_DUE_TODAY", amount);
        },
        setDocuSignKey({commit}, key){
            commit("SET_DOCUSIGN_KEY", key);
        },
        setPromoCode({commit}, promoCode) {
            commit("SET_PROMO_CODE", promoCode);
        },
        setSalesRepAlias({commit}, salesRepAlias) {
            commit("SET_SALES_ALIAS", salesRepAlias);
        },
        setErrors({commit}, errors) {
            commit("SET_ERRORS", errors);
        },
        setDocusignReady({commit}, flag){
            commit("SET_DOCUSIGN_READY", flag)
        }
    },
    getters: {
        getInformation: (state) => (key) => {
            return state[key];
        },
        industry: state => state.personalInformation.industry,
        adminVas: state => state.order.admin.quantity,
        marketingVas: state => state.order.marketing.quantity,
        prospectingVas: state => state.order.prospecting.quantity,
        supportVas: state => state.order.support.quantity,
        step: state => state.currentStep,
        plan: state => state.meta.plan,
        state: state => state,
        errorMessages: state => state.errorMessages,
        docuSignKey: state => state.docusign.key,
        docusignReady: state => state.meta.docusignReady
    },
});

new Vue({
    render: h => h(App),
    store,
}).$mount("#app");
