<template>
    <div>
        <div class="xl:border-t xl:border-b xl:border-gray-200">
            <nav>
                <ul class="overflow-hidden xl:flex xl:border-l xl:border-r xl:border-gray-200 xl:rounded-none">
                    <template v-if="isRealEstate">
                        <li v-for="(step, index) in realEstateSteps" :key="index + 1" class="relative overflow-hidden md:flex-1" @click="$emit('jump', index + 1 )">
                            <ActiveStep v-if="currentStep === index + 1" :details="step"/>
                            <CompletedStep v-if="currentStep > index + 1" :details="step"/>
                            <NewStep v-if="currentStep < index + 1" :details="step"/>
                        </li>
                    </template>
                    <template v-else>
                        <li v-for="(step, index) in nonRealEstateSteps" :key="index + 1" class="relative overflow-hidden md:flex-1" @click="$emit('jump', index + 1 )">
                            <ActiveStep v-if="currentStep === index + 1" :details="step"/>
                            <CompletedStep v-if="currentStep > index + 1" :details="step"/>
                            <NewStep v-if="currentStep < index + 1" :details="step"/>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import ActiveStep from "@/components/Wizard/WizardSteps/ActiveStep";
    import CompletedStep from "@/components/Wizard/WizardSteps/CompletedStep";
    import NewStep from "@/components/Wizard/WizardSteps/NewStep";
    import {mapGetters} from "vuex";

    export default {
        name: "Steps",
        components: {ActiveStep, CompletedStep, NewStep},
        props: {
            currentStep: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                realEstateSteps: [
                    {
                        title: "Your Information",
                        description: "Tell us about yourself",
                        step: 1
                    },
                    {
                        title: "Virtual Assistant",
                        description: "Find a Match",
                        step: 2
                    },
                    {
                        title: "Job Requirements",
                        description: "Job Requirements",
                        step: 3
                    },
                    {
                        title: "Schedule",
                        description: "Pick your VA's Schedule",
                        step: 4
                    },
                    {
                        title: "MOD Movement",
                        description: "Charitable Donations",
                        step: 5
                    },
                    {
                        title: "Review Order",
                        description: "Review Order",
                        step: 6
                    },
                ],
                nonRealEstateSteps: [
                    {
                        title: "Your Information",
                        description: "Tell us about yourself",
                        step: 1
                    },
                    {
                        title: "Virtual Assistant",
                        description: "Find a Match",
                        step: 2
                    },
                    {
                        title: "Schedule",
                        description: "Pick your VA's Schedule",
                        step: 3
                    },
                    {
                        title: "MOD Movement",
                        description: "Charitable Donations",
                        step: 4
                    },
                    {
                        title: "Review Order",
                        description: "Review Order",
                        step: 5
                    },
                ],
                personalInformation: {},
            };
        },
        computed: {
            ...mapGetters([
                "industry",
            ]),
            isRealEstate() {
                return this.industry === "Real Estate";
            },
        },
    };
</script>

<style></style>
