<template>
    <div class="h-full">
        <ValidationObserver ref="personalInfo">
            <div class="md:min-h-3/4">
                <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-2">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            Personal Information
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
                            Please tell us a little about yourself
                        </p>
                    </div>
                    <div class="grid grid-cols-6 gap-4 max-w-2xl">
                        <!--Email-->
                        <div class="col-span-6 sm:col-span-3">
                            <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email</label>
                            <ValidationProvider v-slot="{errors}" rules="required|email|max:80">
                                <div class="relative">
                                    <input
                                            id="email" v-model="personalInformation.email" type="text" :class="{'border-red-500': errors[0]}" :disabled="hasDocuSignKey"
                                            class="block px-3 py-2 mt-1 w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-purple focus:border-purple-300 sm:text-sm sm:leading-5 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    >
                                    <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-2 text-red-500">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                                    </div>
                                </div>
                                <span class="block mt-2 text-xs font-thin text-gray-700">Use The Email You Use To Log In At <span class="italic font-semibold lowercase text-mod-500">myva.myoutdesk.com</span></span>
                                <ErrorText>{{ errors[0] }}</ErrorText>
                            </ValidationProvider>
                        </div>
                        <!--Industry-->
                        <div class="col-span-6 sm:col-span-3">
                            <label for="industry" class="block text-sm font-medium leading-5 text-gray-700">Industry</label>
                            <ValidationProvider v-slot="{errors}" rules="required">
                                <div class="relative">
                                    <select
                                            id="industry"
                                            v-model="personalInformation.industry" :class="{'border-red-500': errors[0]}"
                                            class="block px-3 py-2 mt-1 w-full bg-white rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-purple focus:border-purple-300 sm:text-sm sm:leading-5"
                                    >
                                        <option value="">
                                            Select Industry
                                        </option>
                                        <option v-for="(i, index) in industries" :key="index" :value="i">
                                            {{ i }}
                                        </option>
                                    </select>
                                    <div v-if="errors[0]" class="absolute top-0 right-0 mt-2 mr-8 text-red-500">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/></svg>
                                    </div>
                                </div>
                                <ErrorText>{{ errors[0] }}</ErrorText>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>

        <div class="flex justify-center mt-6">
            <ContinueButton class="flex justify-center w-full md:w-auto" @click.stop.prevent="validate">
                Continue
            </ContinueButton>
        </div>
    </div>
</template>

<script>
    import {extend, ValidationProvider, ValidationObserver} from "vee-validate";
    import {states, countries} from "@/assets/locales";
    import {mapActions, mapGetters} from "vuex";
    import ContinueButton from "@/components/ContinueButton";
    import {required, email, max, numeric, max_value} from "vee-validate/dist/rules";
    import ErrorText from "@/components/Shared/ErrorText";

    extend("max_value", {
        ...max_value,
        message: "Exceeds Max Value",
    });
    extend("numeric", numeric);
    extend("email", email);
    extend("max", max);
    extend("required", {
        ...required,
        message: "This field is required",
    });


    export default {
        name: "YourInformation",
        components: {ErrorText, ContinueButton, ValidationProvider, ValidationObserver},
        data() {
            return {
                states,
                countries,
                personalInformation: {},
                industries: [
                    "Cannabis",
                    "Ecommerce",
                    "Financial Planning",
                    "Franchising",
                    "Healthcare",
                    "Human Resources & PEO",
                    "Insurance",
                    "Marketing Agency",
                    "Mortgage & Lending",
                    "Professional Services",
                    "Property Management",
                    "Real Estate",
                    "Residential Services",
                    "Technology",
                    "Other",
                ],
            };
        },
        computed: {
            ...mapGetters([
                "getInformation",
                "docuSignKey",
            ]),
            hasDocuSignKey(){
                return !!this.docuSignKey;
            },
            isRealEstate() {
                return this.personalInformation.industry === "Real Estate";
            },
        },
        watch: {
            'information.industry'(){
                this.clearPreDefined()
            },
        },
        created() {
            this.personalInformation = this.getInformation("personalInformation");
            document.title = "Step 1: Personal Information";
        },
        methods: {
            ...mapActions([
                "updateRegistration",
                "clearPreDefined",
            ]),
            async validate() {
                let valid = await this.$refs.personalInfo.validate();
                if (!valid) {
                    return;
                }
                let payload = {};

                this.$http.post("/progress", payload).catch(() => {});
                this.updateRegistration({data: this.personalInformation, key: "personalInformation"});
                this.$emit("continue");

            },
        },
    };
</script>
