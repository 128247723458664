<template>
    <div class="overflow-hidden border-t-0 xl:border-0 hidden xl:block">
        <a href="#" class="group">
            <div class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-mod-300 group-focus:bg-mod-200 xl:w-full xl:h-1 xl:bottom-0 xl:top-auto"/>
            <div class="px-6 py-5 flex items-center 2xl:items-start text-sm leading-5 font-medium space-x-4">
                <div class="flex-shrink-0">
                    <div class="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                        <p class="text-gray-500">0{{ details.step }}</p>
                    </div>
                </div>
                <StepTitleAndDescription :title="details.title" :description="details.description"/>
            </div>
        </a>

        <div class="hidden absolute top-0 left-0 w-3 inset-0 xl:block border-l-1 border-gray-200">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import StepTitleAndDescription from "@/components/Wizard/WizardSteps/StepTitleAndDescription";

    export default {
        name: 'NewStep',
        components: {StepTitleAndDescription},
        props: {
            details: {
                required: true,
                type: Object
            }
        }
    }
</script>
