<template>
    <div class="relative min-h-screen">
        <Wizard v-if="(!isRealEstate && step < 6) || (isRealEstate && step < 7)" :current-step="step" @jump="(target) => { if(target >= step ) { return } setStep(target); }"/>
        <Error v-if="errorMessages.length > 0" class="mx-6" :messages="errorMessages" @clear-error="setErrors([])"/>
        <transition
                mode="out-in"
                enter-active-class="transition duration-150 ease-out transform"
                enter-class="opacity-0 translate-x-full"
                enter-to-class="opacity-100 translate-x-0"
                leave-active-class="transition duration-150 ease-in transform"
                leave-class="opacity-100 translate-x-0"
                leave-to-class="opacity-0 -translate-x-full"
        >
            <YourInformation v-if="step === 1" class="p-6" @continue="saveAndContinue"/>
            <VirtualAssistant v-if="step === 2" class="p-6" @continue="saveAndContinue"/>
            <template v-if="isRealEstate">
                <RealEstateRequirements v-if="step === 3" class="p-6" @continue="saveAndContinue"/>
                <Schedule v-if="step === 4" class="p-6" @continue="saveAndContinue"/>
                <ModMovement v-if="step === 5" class="p-6" @continue="saveAndContinue"/>
                <ReviewOrder v-if="step === 6" class="p-6" @continue="saveAndContinue"/>
            </template>
            <template v-else>
                <Schedule v-if="step === 3" class="p-6" @continue="saveAndContinue"/>
                <ModMovement v-if="step === 4" class="p-6" @continue="saveAndContinue"/>
                <ReviewOrder v-if="step === 5" class="p-6" @continue="saveAndContinue"/>
            </template>
        </transition>
        <Spinner :spin="submitting">
            Hang tight, we're creating your order.
        </Spinner>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import Wizard from "@/components/Wizard/Wizard";
    import YourInformation from "@/components/Screens/YourInformation";
    import VirtualAssistant from "@/components/Screens/VirtualAssistant";
    import Error from "@/components/Error";
    import Spinner from "@/components/Shared/Spinner";
    import ModMovement from "@/components/Screens/ModMovement";
    import ReviewOrder from "@/components/Screens/ReviewOrder";
    import Schedule from "@/components/Screens/Schedule";
    import RealEstateRequirements from "@/components/Screens/RealEstateRequirements";
    import {Base64} from "js-base64";

    export default {
        name: "Register",
        components: {RealEstateRequirements, Schedule, ReviewOrder, ModMovement, Spinner, Error, YourInformation, VirtualAssistant, Wizard},
        data() {
            return {
                submitting: false,
            };
        },
        computed: {
            ...mapGetters([
                "step",
                "state",
                "errorMessages",
                "industry",
            ]),
            isRealEstate() {
                return this.industry === "Real Estate";
            },
        },
        methods: {
            ...mapActions([
                "incrementStep",
                "decrementStep",
                "setStep",
                "setErrors",
            ]),
            saveAndContinue() {
                if ((this.isRealEstate && this.step === 6) || (!this.isRealEstate && this.step === 5)) {
                    this.submit();
                } else {
                    this.incrementStep();
                    this.saveProgressInCookie();
                    window.scrollTo(0, 0);
                }
            },
            saveProgressInCookie() {
                try {
                    let encodedString = Base64.encode(JSON.stringify(this.state));
                    let cookieArray = encodedString.match(/.{1,3800}/g) || [];
                    this.$cookie.delete("mod_form_0");
                    this.$cookie.delete("mod_form_1");
                    cookieArray.forEach((cookie, index) => {
                        this.$cookie.set(`mod_form_${index}`, cookie, {expires: 7});
                    });
                } catch (error) {
                    this.$Sentry.captureException(error);
                }
            },
            submit() {
                this.setErrors([]);
                this.submitting = true;
                let payload = {
                    personal: this.state.personalInformation,
                    order: this.state.order,
                    schedule: this.state.schedule,
                    meta: this.state.meta,
                };
                payload.personal.email = payload.personal.email.trim();
                this.$http.post("/addon/create", payload).then(() => {
                    this.saveProgressInCookie();
                    this.incrementStep();
                    this.$emit('complete');
                    if(typeof(gtag) === 'function') {
                        gtag('event', 'conversion', {
                            'send_to': 'G-G1VVK736R3'
                        });
                    } 
                    window.scrollTo(0, 0);

                }).catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.setErrors(error.response.data.message);
                    } else {
                        let errors = [];
                        Object.keys(error.response.data).forEach(key => {
                            for (let i = 0; i < error.response.data[key].length; i++) {
                                errors.push(error.response.data[key][i]);
                            }
                        });
                        this.setErrors(errors);
                    }
                }).finally(() => {
                    this.submitting = false;
                });
            },
            handleLoading(flag) {
                this.loading = flag;
            },
        },
    };
</script>
