<template>
    <button type="button" class="inline-flex items-center px-4 py-2 text-lg font-medium leading-6 text-white rounded-md border border-transparent transition duration-150 ease-in-out disabled:bg-mod-300 bg-mod-600 hover:bg-opacity-90 focus:outline-none focus:border-mod-700 focus:shadow-outline-blue active:bg-mod-700 continue-button" @click="$emit('click', $event)">
        <slot/>
        <span class="ml-1">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>
        </span>
    </button>
</template>

<script>
    export default {
        name: "ContinueButton",
    };
</script>

<style scoped>

</style>
