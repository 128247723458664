<template>
    <div>
        <div class="hidden md:block">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
                Job Requirements
            </h3>
            <p class="hidden mt-1 max-w-2xl text-sm leading-5 text-gray-600 md:block">
                Please provide some information about what you need your Virtual Assistant to do.
            </p>
        </div>

        <ValidationObserver ref="tasks">
            <div class="mt-6 min-h-3/4">
                <div v-if="industry === 'Real Estate'">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Select Your Toolsets
                    </h3>
                    <div id="real_estate_tools" class="flex grid grid-cols-2 gap-8 items-center my-8 select-none lg:grid-cols-5 xl:grid-cols-8">
                        <button
                                v-for="(tool, index) in realEstate.tools" :id="tool.value" :key="index" class="flex overflow-hidden items-center h-16 rounded-md shadow cursor-pointer hover:bg-gray-50 focus:outline-none" :class="included(tool.value)"
                                @click="addTool(tool.value)"
                        >
                            <img :src="imageSource(tool)" :alt="tool.text" class="p-6 pointer-events-none">
                        </button>
                    </div>
                </div>
                <JobRequirements v-if="adminVas !== 0" :order="order.admin" type="admin" @order-update="handleOrderUpdate">
                    <span slot="title">Administrative Assistant Job Requirements</span>
                </JobRequirements>
                <JobRequirements v-if="marketingVas !== 0" class="mt-8" :order="order.marketing" type="marketing" @order-update="handleOrderUpdate">
                    <span slot="title">Marketing Assistant Job Requirements</span>
                </JobRequirements>
                <JobRequirements v-if="prospectingVas !== 0" class="mt-8" :order="order.prospecting" type="prospecting" @order-update="handleOrderUpdate">
                    <span slot="title">Prospecting Assistant Job Requirements</span>
                </JobRequirements>
                <JobRequirements v-if="supportVas !== 0" class="mt-8" :order="order.support" type="support" @order-update="handleOrderUpdate">
                    <span slot="title">Support Assistant Job Requirements</span>
                </JobRequirements>
            </div>
        </Validationobserver>

        <div class="grid grid-cols-1 gap-4 mt-4 md:hidden">
            <BackButton class="flex justify-center text-center" @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton class="flex justify-center text-center" @click.stop.prevent="validate">
                Continue
            </ContinueButton>
        </div>
        <div class="hidden justify-center space-x-4 md:flex">
            <BackButton @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton @click.stop.prevent="validate">
                Continue
            </ContinueButton>
        </div>
    </div>
</template>

<script>
    import {realEstate} from "@/assets/predefined";
    import {ValidationObserver} from "vee-validate";
    import {mapActions, mapGetters} from "vuex";
    import ContinueButton from "@/components/ContinueButton";
    import BackButton from "@/components/BackButton";
    import JobRequirements from "@/components/JobRequirements";


    export default {
        name: "RealEstateRequirements",
        components: {JobRequirements, BackButton, ContinueButton, ValidationObserver},
        data() {
            return {
                realEstate,
                order: {},
                tools: [],
                vaTypes: ['admin', 'prospecting', 'marketing', 'support']
            };
        },
        computed: {
            ...mapGetters([
                "getInformation",
                "industry",
                "adminVas",
                "marketingVas",
                "prospectingVas",
                "supportVas",
            ]),
        },
        created() {
            this.order = this.getInformation("order");
            this.tools = this.order.admin.predefined.tools
            document.title = "Step 3: Tasks & Tools";
        },
        methods: {
            ...mapActions({
                updateRegistration: "updateRegistration",
                decrementStep: "decrementStep",
            }),
            addTool(tool) {
                if (!this.tools.includes(tool)) {
                    this.tools.push(tool);
                } else {
                    this.tools = this.tools.filter((key) => {
                        if (key !== tool) {
                            return key;
                        }
                    });
                }

                this.vaTypes.forEach(type => {
                    this.order[type].predefined.tools = this.tools
                })

            },
            included(tool) {
                if (this.tools.includes(tool)) {
                    return "border-2 border-mod-500 bg-orange-50";
                }
            },
            async validate() {
                let valid = await this.$refs.tasks.validate();
                if (!valid) {
                    return;
                }
                this.updateRegistration({data: this.order, key: "order"});
                this.$emit("continue");
            },
            imageSource(tool) {
                return "/CRMLogos/" + tool.src;
            },
            handleOrderUpdate({order, type, predefinedTasks}){
                this.order[type].outcome = order.outcome;
                this.order[type].tools = order.tools;
                this.order[type].other = order.other;
                this.order[type].predefined.tasks = predefinedTasks
            }
        },
    };
</script>

<style scoped>

</style>
