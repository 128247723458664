<template>
    <div class="block relative pb-12">
        <div class="min-h-3/4">
            <div class="w-full">
                <div class="flex flex-col justify-center items-center">
                    <iframe
                            id="iframeplayer" ref="player" src="https://player.vimeo.com/video/295315417" width="100%" height="100%"
                            class="w-full xl:max-w-3xl md:max-w-2xl"
                    />
                    <div class="w-full xl:max-w-3xl md:max-w-2xl">
                        <div>
                            <h3 class="block mb-3 font-medium leading-6 text-gray-900">
                                The MOD Movement
                            </h3>
                            <p class="leading-5 text-gray-600 md:block">
                                The MOD Movement is a charity founded as a result of the kindness and generosity of many of the wonderful individuals who work for MyOutDesk. By donating their money, time, and hearts, members of the MyOutDesk team have improved the lives of people everywhere.
                            </p>
                            <h3 class="block my-3 font-medium leading-6 text-gray-900">
                                Servants Heart
                            </h3>
                            <p class="mt-2 leading-5 text-gray-600 md:block">
                                Being of service is a core value at MOD, and it's deeply ingrained into every aspect of our business philosophy. Our mission is to create impact in the world by serving underprivileged communities, both here in the United States and abroad.
                            </p>
                            <p class="mt-2 leading-5 text-gray-600 md:block">
                                <strong>100% of your donations</strong> are tax deductible and <strong>MyOutDesk will match your monthly donations</strong>, creating even more impact.
                            </p>
                            <h3 class="block mt-3 mb-6 font-medium leading-6 text-gray-900">
                                Together, we can make a difference.
                            </h3>
                            <ValidationObserver ref="modMovementValidator">
                                <div>
                                    <label for="modMovement" class="flex items-center">
                                        <input id="modMovement" v-model="meta.modMovementContributor" type="checkbox" class="mr-2 rounded border border-gray-400 transition duration-150 ease-in-out md:w-5 md:h-5 form-checkbox text-mod-600 foucs:outline-none"> Yes, I Will Donate Monthly
                                    </label>
                                </div>
                                <div v-if="meta.modMovementContributor" class="grid grid-cols-2 gap-4 mt-8 lg:grid-cols-4">
                                    <button type="button" class="p-4 font-semibold text-center rounded cursor-pointer text-mod-900" :class="[isDonationAmount(75) ? 'bg-mod-400 border-mod-600 text-mod-900' : 'bg-mod-100 border border-mod-200']" @click="updateDonation(75)">
                                        $75
                                    </button>
                                    <button type="button" class="p-4 w-full font-semibold text-center rounded cursor-pointer text-mod-900" :class="[isDonationAmount(50) ? 'bg-mod-400 border-mod-600 text-mod-900' : 'bg-mod-100 border border-mod-200']" @click="updateDonation(50)">
                                        $50
                                    </button>
                                    <button type="button" class="p-4 font-semibold text-center rounded cursor-pointer text-mod-900" :class="[isDonationAmount(25) ? 'bg-mod-400 border-mod-600 text-mod-900' : 'bg-mod-100 border border-mod-200']" @click="updateDonation(25)">
                                        $25
                                    </button>
                                    <button class="p-4 font-semibold text-center rounded border bg-mod-100 border-mod-200 text-mod-900" :class="[isCustomModMovementDonation() ? 'bg-mod-400 border-mod-600 text-mod-900' : '']" @click="meta.modMovementContribution = 100.00">
                                        Custom
                                    </button>
                                    <div class="col-span-2 text-center lg:col-span-4">
                                        <p class="font-semibold">
                                            MyOutDesk will match your contribution!
                                        </p>
                                    </div>
                                    <div class="col-span-2 lg:col-span-4">
                                        <p class="leading-5 text-gray-600">
                                            The MOD Movement has been doing charitable works since 2013 and is a California non-profit corporation. Tax exempt 501c3 status has been approved since March 15th 2018, and donors can deduct contributions under IRC Section 170.
                                        </p>
                                    </div>
                                </div>
                                <div v-if="meta.modMovementContributor && isCustomModMovementDonation()" class="mt-8">
                                    <label for="mod_movement_contribution" class="block text-sm font-medium leading-5 text-gray-700">Custom Monthly Donation Amount</label>
                                    <ValidationProvider v-slot="{errors}" rules="required|max_value:9999|numeric|min:0">
                                        <input
                                                id="mod_movement_contribution" v-model="meta.modMovementContribution" type="number" min="0" class="block px-3 py-2 mt-1 w-full rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-purple focus:border-purple-300 sm:text-sm sm:leading-5"
                                        >
                                        <ErrorText v-if="errors[0]">
                                            Donation amount must be above 0, less than 10,000, and must be a whole number (no currency symbols such as $, or commas, periods)
                                        </ErrorText>
                                    </ValidationProvider>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden justify-center mt-6 space-x-4 md:flex">
            <BackButton @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton @click="validate">
                Continue
            </ContinueButton>
        </div>
        <div class="grid grid-cols-1 gap-4 mt-6 md:hidden">
            <BackButton class="flex justify-center text-center" @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton class="flex justify-center text-center" @click="validate">
                Continue
            </ContinueButton>
        </div>
    </div>
</template>

<script>
    import {extend} from "vee-validate";
    import {min, numeric} from "vee-validate/dist/rules";
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import {mapActions, mapGetters} from "vuex";
    import ContinueButton from "@/components/ContinueButton";
    import BackButton from "@/components/BackButton";
    import ErrorText from "@/components/Shared/ErrorText";

    extend("min", {
        ...min,
    });

    extend("numeric", {
        ...numeric,
    });

    export default {
        name: "ModMovement",
        components: {
            ErrorText,
            ContinueButton, BackButton, ValidationProvider, ValidationObserver,
        },
        data() {
            return {
                meta: null,
            };
        },
        computed: {
            ...mapGetters([
                "getInformation",
                "industry",
            ]),
            isRealEstate() {
                return this.industry === "Real Estate";
            },
        },
        created() {
            if (this.isRealEstate) {
                document.title = "Step 5: MOD  Movement";
            } else {
                document.title = "Step 4: MOD  Movement";
            }
            this.meta = this.getInformation("meta");
        },
        methods: {
            ...mapActions([
                "updateRegistration",
                "decrementStep",
                "setErrors",
            ]),
            updateDonation(amount) {
                this.meta.modMovementContribution = amount;
            },
            isDonationAmount(amount) {
                return this.meta.modMovementContribution === amount;
            },
            async validate() {
                this.amountError = false;
                if (this.meta.modMovementContributor) {
                    let valid = await this.$refs.modMovementValidator.validate();
                    if (!valid) {
                        return false;
                    }
                } else {
                    this.meta.modMovementContribution = 0;
                }
                this.updateRegistration({data: this.meta, key: "meta"});
                this.$emit("continue");
            },
            isCustomModMovementDonation() {
                return this.meta.modMovementContribution !== null && this.meta.modMovementContribution !== 25 && this.meta.modMovementContribution !== 50 && this.meta.modMovementContribution !== 75;
            },
        },
    };
</script>
<style lang="css">
@media (min-width: 320px) {
    #iframeplayer {
        height: 200px;
    }
}

@media (min-width: 768px) {
    #iframeplayer {
        height: 500px;
    }
}
</style>
