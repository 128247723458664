<template>
    <div class="border border-gray-200 overflow-hidden border-b-0 xl:border-0 hidden xl:block">
        <a href="#" class="group">
            <div class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 group-focus:bg-gray-200 xl:w-full xl:h-1 xl:bottom-0 xl:top-auto"/>
            <div class="px-6 py-5 flex items-center 2xl:items-start text-sm leading-5 font-medium space-x-4">
                <div class="flex-shrink-0">
                    <div class="w-10 h-10 flex items-center justify-center bg-mod-600 rounded-full">
                        <svg class="w-6 h-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                        </svg>
                    </div>
                </div>
                <StepTitleAndDescription :title="details.title" :description="details.description"/>
            </div>
        </a>
        <div v-if="details.step !== 0" class="hidden absolute top-0 left-0 w-3 inset-0 xl:block border-l-1 border-gray-200">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import StepTitleAndDescription from "@/components/Wizard/WizardSteps/StepTitleAndDescription";
    export default {
        name: 'CompletedStep',
        components: {StepTitleAndDescription},
        props: {
            details: {
                required: true,
                type: Object
            }
        }
    }
</script>
