<template>
    <div v-show="show" class="overflow-y-auto fixed inset-0 z-10">
        <div class="flex justify-center items-end px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
            <transition
                    mode="out-in"
                    enter-active-class="duration-300 ease-out"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="duration-200 ease-in"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
            >
                <div v-if="show" class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"/>
                </div>
            </transition>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <transition
                    mode="out-in"
                    enter-active-class="duration-300 ease-out"
                    enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="duration-200 ease-in"
                    leave-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <div class="inline-block overflow-hidden px-4 pt-5 pb-4 text-left align-bottom bg-white rounded-lg shadow-xl transition-all transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="sm:flex sm:items-start">
                        <div class="flex flex-shrink-0 justify-center items-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <svg class="w-6 h-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                            </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 id="modal-headline" class="text-lg font-medium leading-6 text-gray-900">
                                Reset Progress
                            </h3>
                            <div class="mt-2">
                                <p class="text-sm leading-5 text-gray-600">
                                    Are you sure you want to reset your progress? The entire form will be reset.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                            <button id="confirm_reset_button" type="button" class="inline-flex justify-center px-4 py-2 w-full text-base font-medium leading-6 text-white bg-red-600 rounded-md border border-transparent shadow-sm transition duration-150 ease-in-out hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5" @click="$emit('reset')">
                                Reset
                            </button>
                        </span>
                        <span class="flex mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                            <button id="cancel_reset" type="button" class="inline-flex justify-center px-4 py-2 w-full text-base font-medium leading-6 text-gray-700 bg-white rounded-md border border-gray-300 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5" @click="$emit('cancel')">
                                Cancel
                            </button>
                        </span>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Alert",
        props:{
            show: {type: Boolean, default: false}
        }
    };
</script>

<style scoped>

</style>
