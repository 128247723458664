<template>
    <div>
        <div v-for="(task, index) in definedTasks" :key="createId(index)" class="mb-4">
            <div class="flex items-center">
                <input :id="createId(index)" v-model="selectedTasks" type="checkbox" :value="task" class="w-4 h-4 rounded border border-gray-400 transition duration-150 ease-in-out form-checkbox text-mod-600">
                <div class="ml-3 text-sm leading-5">
                    <label :for="createId(index)" class="font-medium text-gray-700">{{ task }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "PredefinedTasks",
        props: {
            type: {type: String, default: ""},
            definedTasks: {type: Array, default: () => []},
            tasks: {type: Array, default: () => []},
        },
        data() {
            return {
                selectedTasks: this.tasks,
            };
        },
        watch: {
            selectedTasks(newTasks){
                this.$emit('value-select', newTasks);
            }
        },

        methods: {
            createId(index) {
                return this.type + "_check_" + index;
            },
        },
    };
</script>

<style scoped>

</style>
