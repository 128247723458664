<template>
    <div class="mt-0.5 min-w-0">
        <h3 class="text-xs leading-4 font-semibold uppercase tracking-wide">
            {{ title }}
        </h3>
        <p class="text-xs font-medium text-gray-500 hidden 2xl:block">
            {{ description }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'StepTitleAndDescription',
        props: {
            title: {
                required: true,
                type: String
            },
            description: {
                required: true,
                type: String
            }
        }
    }
</script>

<style scoped>

</style>
