let states = {
    'USA': [
        {
            value: 'AL',
            text: 'Alabama',
            country: 'USA'
        },
        {
            value: 'AK',
            text: 'Alaska',
            country: 'USA'
        },
        {
            value: 'AZ',
            text: 'Arizona',
            country: 'USA'
        },
        {
            value: 'AR',
            text: 'Arkansas',
            country: 'USA'
        },
        {
            value: 'CA',
            text: 'California',
            country: 'USA'
        },
        {
            value: 'CO',
            text: 'Colorado',
            country: 'USA'
        },
        {
            value: 'CT',
            text: 'Connecticut',
            country: 'USA'
        },
        {
            value: 'DC',
            text: 'District of Columbia',
            country: 'USA'
        },
        {
            value: 'DE',
            text: 'Delaware',
            country: 'USA'
        },
        {
            value: 'FL',
            text: 'Florida',
            country: 'USA'
        },
        {
            value: 'GA',
            text: 'Georgia',
            country: 'USA'
        },
        {
            value: 'HI',
            text: 'Hawaii',
            country: 'USA'
        },
        {
            value: 'ID',
            text: 'Idaho',
            country: 'USA'
        },
        {
            value: 'IL',
            text: 'Illinois',
            country: 'USA'
        },
        {
            value: 'IN',
            text: 'Indiana',
            country: 'USA'
        },
        {
            value: 'IA',
            text: 'Iowa',
            country: 'USA'
        },
        {
            value: 'KS',
            text: 'Kansas',
            country: 'USA'
        },
        {
            value: 'KY',
            text: 'Kentucky',
            country: 'USA'
        },
        {
            value: 'LA',
            text: 'Louisiana',
            country: 'USA'
        },
        {
            value: 'ME',
            text: 'Maine',
            country: 'USA'
        },
        {
            value: 'MD',
            text: 'Maryland',
            country: 'USA'
        },
        {
            value: 'MA',
            text: 'Massachusetts',
            country: 'USA'
        },
        {
            value: 'MI',
            text: 'Michigan',
            country: 'USA'
        },
        {
            value: 'MN',
            text: 'Minnesota',
            country: 'USA'
        },
        {
            value: 'MS',
            text: 'Mississippi',
            country: 'USA'
        },
        {
            value: 'MO',
            text: 'Missouri',
            country: 'USA'
        },
        {
            value: 'MT',
            text: 'Montana',
            country: 'USA'
        },
        {
            value: 'NE',
            text: 'Nebraska',
            country: 'USA'
        },
        {
            value: 'NV',
            text: 'Nevada',
            country: 'USA'
        },
        {
            value: 'NH',
            text: 'New Hampshire',
            country: 'USA'
        },
        {
            value: 'NJ',
            text: 'New Jersey',
            country: 'USA'
        },
        {
            value: 'NM',
            text: 'New Mexico',
            country: 'USA'
        },
        {
            value: 'NY',
            text: 'New York',
            country: 'USA'
        },
        {
            value: 'NC',
            text: 'North Carolina',
            country: 'USA'
        },
        {
            value: 'ND',
            text: 'North Dakota',
            country: 'USA'
        },
        {
            value: 'OH',
            text: 'Ohio',
            country: 'USA'
        },
        {
            value: 'OK',
            text: 'Oklahoma',
            country: 'USA'
        },
        {
            value: 'OR',
            text: 'Oregon',
            country: 'USA'
        },
        {
            value: 'PA',
            text: 'Pennsylvania',
            country: 'USA'
        },
        {
            value: 'RI',
            text: 'Rhode Island',
            country: 'USA'
        },
        {
            value: 'SC',
            text: 'South Carolina',
            country: 'USA'
        },
        {
            value: 'SD',
            text: 'South Dakota',
            country: 'USA'
        },
        {
            value: 'TN',
            text: 'Tennessee',
            country: 'USA'
        },
        {
            value: 'TX',
            text: 'Texas',
            country: 'USA'
        },
        {
            value: 'UT',
            text: 'Utah',
            country: 'USA'
        },
        {
            value: 'VT',
            text: 'Vermont',
            country: 'USA'
        },
        {
            value: 'VA',
            text: 'Virginia',
            country: 'USA'
        },
        {
            value: 'WA',
            text: 'Washington',
            country: 'USA'
        },
        {
            value: 'WV',
            text: 'West Virginia',
            country: 'USA'
        },
        {
            value: 'WI',
            text: 'Wisconsin',
            country: 'USA'
        },
        {
            value: 'WY',
            text: 'Wyoming',
            country: 'USA'
        },
        {
            value: 'AS',
            text: 'American Samoa',
            country: 'USA'
        },
        {
            value: 'GU',
            text: 'Guam',
            country: 'USA'
        },
        {
            value: 'MP',
            text: 'Northern Mariana Islands',
            country: 'USA'
        },
        {
            value: 'PR',
            text: 'Puerto Rico',
            country: 'USA'
        },
        {
            value: 'UM',
            text: 'United States Minor Outlying Islands',
            country: 'USA'
        },
        {
            value: 'VI',
            text: 'Virgin Islands',
            country: 'USA'
        }
    ],
    'CANADA': [
        {
            value: 'AB',
            text: 'Alberta',
            country: 'CANADA'
        },
        {
            value: 'BC',
            text: 'British Columbia',
            country: 'CANADA'
        },
        {
            value: 'MB',
            text: 'Manitoba',
            country: 'CANADA'
        },
        {
            value: 'NB',
            text: 'New Brunswick',
            country: 'CANADA'
        },
        {
            value: 'NL',
            text: 'Newfoundland and Labrador',
            country: 'CANADA',
            alt: ['Newfoundland', 'Labrador']
        },
        {
            value: 'NS',
            text: 'Nova Scotia',
            country: 'CANADA'
        },
        {
            value: 'NU',
            text: 'Nunavut',
            country: 'CANADA'
        },
        {
            value: 'NT',
            text: 'Northwest Territories',
            country: 'CANADA'
        },
        {
            value: 'ON',
            text: 'Ontario',
            country: 'CANADA'
        },
        {
            value: 'PE',
            text: 'Prince Edward Island',
            country: 'CANADA'
        },
        {
            value: 'QC',
            text: 'Quebec',
            country: 'CANADA'
        },
        {
            value: 'SK',
            text: 'Saskatchewan',
            country: 'CANADA'
        },
        {
            value: 'YT',
            text: 'Yukon',
            country: 'CANADA'
        }
    ]
};

let countries = [
    {
        text: 'United States',
        value: 'USA',
    },
    {
        text: 'Canada',
        value: 'CANADA',
    }
];

let timeIntervals = [
    {
        text: '12:00 AM',
        value: '12:00 AM'
    },
    {
        text: '12:30 AM',
        value: '12:30 AM'
    },
    {
        text: '01:00 AM',
        value: '01:00 AM'
    },
    {
        text: '01:30 AM',
        value: '01:30 AM'
    },
    {
        text: '02:00 AM',
        value: '02:00 AM'
    },
    {
        text: '02:30 AM',
        value: '02:30 AM'
    },
    {
        text: '03:00 AM',
        value: '03:00 AM'
    },
    {
        text: '03:30 AM',
        value: '03:30 AM'
    },
    {
        text: '04:00 AM',
        value: '04:00 AM'
    },
    {
        text: '04:30 AM',
        value: '4:30 AM'
    },
    {
        text: '05:00 AM',
        value: '05:00 AM'
    },
    {
        text: '05:30 AM',
        value: '05:30 AM'
    },
    {
        text: '06:00 AM',
        value: '06:00 AM'
    },
    {
        text: '06:30 AM',
        value: '06:30 AM'
    },
    {
        text: '07:00 AM',
        value: '07:00 AM'
    },
    {
        text: '07:30 AM',
        value: '07:30 AM'
    },
    {
        text: '08:00 AM',
        value: '08:00 AM'
    },
    {
        text: '08:30 AM',
        value: '08:30 AM'
    },
    {
        text: '09:00 AM',
        value: '09:00 AM'
    },
    {
        text: '09:30 AM',
        value: '09:30 AM'
    },
    {
        text: '10:00 AM',
        value: '10:00 AM'
    },
    {
        text: '10:30 AM',
        value: '10:30 AM'
    },
    {
        text: '11:00 AM',
        value: '11:00 AM'
    },
    {
        text: '11:30 AM',
        value: '11:30 AM'
    },
    {
        text: '12:00 PM',
        value: '12:00 PM'
    },
    {
        text: '12:30 PM',
        value: '12:30 PM'
    },
    {
        text: '01:00 PM',
        value: '01:00 PM'
    },
    {
        text: '01:30 PM',
        value: '01:30 PM'
    },
    {
        text: '02:00 PM',
        value: '02:00 PM'
    },
    {
        text: '02:30 PM',
        value: '02:30 PM'
    },
    {
        text: '03:00 PM',
        value: '03:00 PM'
    },
    {
        text: '03:30 PM',
        value: '03:30 PM'
    },
    {
        text: '04:00 PM',
        value: '04:00 PM'
    },
    {
        text: '04:30 PM',
        value: '4:30 PM'
    },
    {
        text: '05:00 PM',
        value: '05:00 PM'
    },
    {
        text: '05:30 PM',
        value: '05:30 PM'
    },
    {
        text: '06:00 PM',
        value: '06:00 PM'
    },
    {
        text: '06:30 PM',
        value: '06:30 PM'
    },
    {
        text: '07:00 PM',
        value: '07:00 PM'
    },
    {
        text: '07:30 PM',
        value: '07:30 PM'
    },
    {
        text: '08:00 PM',
        value: '08:00 PM'
    },
    {
        text: '08:30 PM',
        value: '08:30 PM'
    },
    {
        text: '09:00 PM',
        value: '09:00 PM'
    },
    {
        text: '09:30 PM',
        value: '09:30 PM'
    },
    {
        text: '10:00 PM',
        value: '10:00 PM'
    },
    {
        text: '10:30 PM',
        value: '10:30 PM'
    },
    {
        text: '11:00 PM',
        value: '11:00 PM'
    },
    {
        text: '11:30 PM',
        value: '11:30 PM'
    }
];

module.exports = {
    states,
    countries,
    timeIntervals
};
