<template>
    <div class="relative">
        <div class="hidden md:block">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
                Virtual Assistant Needs
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
                Please select which kind of Virtual Assistant(s) you need
            </p>
        </div>

        <div class="mt-6 md:min-h-3/4">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-4 lg:gap-14">
                <VaCard
                        class="flex flex-col col-span-4 rounded-lg shadow lg:col-span-2 2xl:col-span-1" type="admin" :order="order.admin" :list-items="adminListItems" @increment="increment('admin')"
                        @order-update="handleOrderUpdate" @decrement="decrement('admin')" @update-tasks="handleTaskUpdate" @update="handleUpdate" @editing="toggleEditMode"
                >
                    <img slot="img" class="w-full rounded-t-lg" src="./../../assets/admin_virtual_assistant.jpg" alt="">
                    <span slot="title">ADMINISTRATIVE</span>
                    <span slot="sub-title">VIRTUAL ASSISTANTS</span>
                    <span slot="supporting-text">Let our Administrative Virtual Assistants handle your routine and repetitive tasks like database and CRM management, scheduling, email & calendar management, basic administrative support, listing support, phone answering & reception, bookkeeping, and so much more!</span>
                </VaCard>
                <VaCard
                        class="flex flex-col col-span-4 rounded-lg shadow lg:col-span-2 2xl:col-span-1" type="prospecting" :order="order.prospecting" :list-items="prospectingListItems" @increment="increment('prospecting')"
                        @order-update="handleOrderUpdate" @decrement="decrement('prospecting')" @update-tasks="handleTaskUpdate" @update="handleUpdate" @editing="toggleEditMode"
                >
                    <img slot="img" class="w-full rounded-t-lg" src="./../../assets/prospecting_virtual_assistant.jpg" alt="">
                    <span slot="title">PROSPECTING</span>
                    <span slot="sub-title">VIRTUAL ASSISTANTS</span>
                    <span slot="supporting-text">Hire an experienced Inside Sales Agent (ISA) to handle prospecting calls, like warm & cold calling, lead screening & qualification, prospect & client follow-up & nurturing. Our ISA Virtual Assistants create new opportunities and improve customer service & long-term clientretention.</span>
                </VaCard>
                <VaCard
                        class="flex flex-col col-span-4 rounded-lg shadow lg:col-span-2 2xl:col-span-1" type="support" :order="order.support" :list-items="supportListItems" @increment="increment('support')"
                        @order-update="handleOrderUpdate" @decrement="decrement('support')" @update-tasks="handleTaskUpdate" @update="handleUpdate" @editing="toggleEditMode"
                >
                    <img slot="img" class="w-full rounded-t-lg" src="./../../assets/support_virtual_assistant.jpg" alt="">
                    <span slot="title">SERVICE & SUPPORT</span>
                    <span slot="sub-title">VIRTUAL ASSISTANTS</span>
                    <span slot="supporting-text">Our Customer Service Virtual Assistants are trained to handle all of your customer inquiries, help desk tickets & support tasks to ensure your customers are fully satisfied! We take pride in delivering the highest quality virtual assistants, letting you rapidly scale to meet growing demand.</span>
                </VaCard>
                <VaCard
                        class="flex flex-col col-span-4 rounded-lg shadow lg:col-span-2 2xl:col-span-1" type="marketing" :order="order.marketing" :list-items="marketingListItems" @increment="increment('marketing')"
                        @order-update="handleOrderUpdate" @decrement="decrement('marketing')" @update-tasks="handleTaskUpdate" @update="handleUpdate" @editing="toggleEditMode"
                >
                    <img slot="img" class="w-full rounded-t-lg" src="./../../assets/marketing_virtual_assistant.jpg" alt="">
                    <span slot="title">MARKETING</span>
                    <span slot="sub-title">VIRTUAL ASSISTANTS</span>
                    <span slot="supporting-text">Our Marketing Virtual Assistants can tackle your online marketing, social media management, collateral development, video editing & production, graphic design & layout, website management, SEO, blogging, and more — professionally delivered to make your brand stand out.</span>
                </VaCard>
            </div>
        </div>
        <div class="grid grid-cols-1 gap-4 mt-6 md:mt-0 md:hidden">
            <BackButton class="flex justify-center text-center" @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton class="flex justify-center text-center" @click.stop.prevent="validate">
                Continue
            </ContinueButton>
        </div>

        <p v-if="noVaError" class="mt-2 mt-8 text-lg text-center text-red-500">
            You Must Select At Least One Virtual Assistant
        </p>

        <p v-if="cardInEditModeError" class="mt-2 mt-8 text-lg text-center text-red-500">
            Please make sure you've hit "Update" on your VA orders before continuing
        </p>

        <div class="hidden justify-center items-center mt-4 space-x-4 md:flex">
            <BackButton @click.stop.prevent="decrementStep">
                Back
            </BackButton>
            <ContinueButton @click.stop.prevent="validate">
                Continue
            </ContinueButton>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {realEstateCardJobs, genericCardJobs} from "@/assets/predefined";
    import ContinueButton from "@/components/ContinueButton";
    import VaCard from "@/components/VirtualAssistant/VaCard";
    import BackButton from "@/components/BackButton";

    export default {
        name: "VirtualAssistant",
        components: {VaCard, ContinueButton, BackButton},
        data() {
            return {
                order: {
                    adminVas: {
                        quantity: 0
                    },
                    marketingVas: {
                        quantity: 0
                    },
                    prospectingVas: {
                        quantity: 0
                    },
                    supportVas: {
                        quantity: 0
                    },
                },
                noVaError: false,
                cardInEditMode: false,
                cardInEditModeError: false,
            };
        },
        computed: {
            ...mapGetters([
                "getInformation",
                "industry"
            ]),
            adminListItems() {
                if(this.industry.toLowerCase() === 'real estate') {
                    return realEstateCardJobs.admin;
                }
                return genericCardJobs.admin;
            },
            prospectingListItems() {
                if(this.industry.toLowerCase() === 'real estate') {
                    return realEstateCardJobs.prospecting;
                }
                return genericCardJobs.prospecting;
            },
            supportListItems() {
                if(this.industry.toLowerCase() === 'real estate') {
                    return realEstateCardJobs.support;
                }
                return genericCardJobs.support;
            },
            marketingListItems() {
                if(this.industry.toLowerCase() === 'real estate') {
                    return realEstateCardJobs.marketing;
                }
                return genericCardJobs.marketing;
            }
        },
        watch: {
            description: {
                deep: true,
                handler(newValue) {
                    this.$emit("order-update", {
                        order: newValue,
                        type: this.type,
                        predefinedTasks: this.selectedPredefined,
                    });
                },
            },
        },
        created() {
            this.order = this.getInformation("order");
            document.title = "Step 2: Virtual Assistants";
        },
        methods: {
            ...mapActions([
                "updateRegistration",
                "decrementStep",
            ]),
            validate() {
                if(this.cardInEditMode) {
                    this.cardInEditModeError = true;
                    return false;
                }
                if(!this.hasAtLeastOneVa()) {
                    this.noVaError = true;
                    return false;
                }
                this.noVaError = false;
                this.updateRegistration({data: this.order, key: "order"});
                this.$emit("continue");
            },
            hasAtLeastOneVa() {
                for(let type of Object.keys(this.order)) {
                    if(this.order[type].quantity > 0) {
                        return true;
                    }
                }
                return false;
            },
            toggleEditMode() {
                if(this.industry !== 'Real Estate') {
                    this.cardInEditMode = true;
                    this.cardInEditModeError = false;
                }
            },
            increment(type) {
                if(this.order[type].quantity < 5) {
                    this.order[type].quantity++;
                    this.noVaError = false;
                }
            },
            decrement(type) {
                if(this.order[type].quantity > 0) {
                    this.order[type].quantity--;
                }
            },
            stopEditing() {
                this.cardInEditMode = false;
                this.cardInEditModeError = false;
            },
            handleUpdate(args) {
                this.stopEditing();
                this.order[args.type].quantity = args.value;
            },
            handleTaskUpdate(args) {
                this.stopEditing();
                this.order[args.type].outcome = args.outcome;
                this.order[args.type].tools = args.tools;
                this.order[args.type].other = args.other;
                this.order[args.type].predefined.tools = args.predefined.tools;
                this.order[args.type].predefined.tasks = args.predefined.tasks;
            },
            handleOrderUpdate({order, type, predefinedTasks}) {
                this.stopEditing();
                this.order[type].outcome = order.outcome;
                this.order[type].tools = order.tools;
                this.order[type].other = order.other;
                this.order[type].predefined.tasks = predefinedTasks
            }
        },
    };
</script>

<style scoped>

</style>
